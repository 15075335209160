import { useMemo } from "react";
import { create } from "zustand";
import { computed } from 'zustand-middleware-computed-state';
import { persist, subscribeWithSelector } from "zustand/middleware";
import { useArtStyles } from "../DesignerV2/hooks/ArtStyles";

export const usePromptOptions = () => {
    const { items } = useArtStyles();

    const visibilityOptions = [
        { label: 'Public', value: 'public' },
        { label: 'Private', value: 'private' },
    ]
    const aspectRatioOptions = [
        { label: '1:1', value: '1:1' },
        { label: '3:4', value: '3:4' },
        { label: '4:3', value: '4:3' },
        { label: '4:5', value: '4:5' },
        { label: '9:16', value: '9:16' },
        { label: '16:9', value: '16:9' },
    ]
    const availableTags = [
        "Shirt Design", "Simple Shapes"
    ]

    const artStyles = useMemo(() => items?.splice(0, 13) || [], [items]);

    return {
        visibilityOptions,
        aspectRatioOptions,
        availableTags,
        artStyles,
        allItems: items
    }
}

export const usePromptBuilder = create(
    subscribeWithSelector(
        persist(
            computed(
                (set, get) => ({
                    account: 2,
                    aiModel: "midjourney-via-imagineapi",
                    original_prompt_id: null,
                    cref: null,
                    sref: null,
                    prompt: '',
                    precisions: [],
                    artStyle: null,
                    negative: true,
                    negatives: [],
                    tiles: false,
                    plainBg: true,
                    ar: '1:1',
                    visibility: 'private',
                    set: (key, value) => set({ [key]: value }),
                    resetForm: () => set({
                        cref: null,
                        sref: null,
                        prompt: '',
                        visibility: 'private',
                    }),
                    reset: () => set({
                        account: 2,
                        aiModel: "midjourney-via-imagineapi",
                        original_prompt_id: null,
                        cref: null,
                        sref: null,
                        prompt: '',
                        precisions: [],
                        artStyle: null,
                        negative: false,
                        negatives: [],
                        tiles: false,
                        plainBg: true,
                        ar: '1:1',
                        visibility: 'private',
                    }),
                }),
                (state) => {
                    function getPayload() {
                        return {
                            aiModel: state.aiModel, accountId: state.account,
                            cref: state.cref, sref: state.sref, prompt: state.prompt,
                            precisions: state.precisions, artStyle: state.artStyle,
                            negative: state.negative, negatives: state.negatives,
                            tiles: state.tiles, plainBg: state.plainBg,
                            ar: state.ar, visibility: state.visibility,
                            original_prompt_id: state.original_prompt_id
                        }
                    }
                    function getErrors() {
                        const errors = [];
                        if (!state.prompt || state.prompt?.length < 3) {
                            errors.push('Prompt or subject is required and must a valid subject/prompt');
                        }
                        if (!state.account) {
                            errors.push('Select which AI account to be use for image generation');
                        }
                        return errors;
                    }
                    function getGeneratedPrompt() {
                        let build = [
                            state.prompt || '',
                            state.precisions.length > 0 ? `(${state.precisions.join(', ')})` : '',
                            state.artStyle ? `in the style of ${state.artStyle}` : '',
                            state.plainBg ? ', plain background' : '',
                            state.negative && state.negatives.length > 0 ? `--no ${state.negatives.join(', ')}` : '',
                            state.cref ? `--cref ${state.cref}` : '',
                            state.sref ? `--sref ${state.sref}` : '',
                            state.ar ? `--ar ${state.ar}` : '',
                            state.tiles ? '--tile' : '',
                        ];

                        return build.join(' ').trim();
                    }
                    return { payload: getPayload(), errors: getErrors(), generatedPrompt: getGeneratedPrompt() }
                }
            )
        )
    )
);
